import React from 'react';
import '../App.css';
import { services } from '../constants';
import ButtonLink from './ButtonLink';
import Footer from './Footer';

const ServiceCard = ({ service }) => (
  <div className='sm:w-[250px] w-full'>
    <div
      className='w-full green-pink-gradient p-[1px] rounded-[20px]'>
      <div
        className='rounded-[20px] py-5 px-12 min-h-[280px] flex justify-evenly items-center flex-col'
        style={{ background: '#151030' }}>
        <img
          src={service.icon}
          alt='some_icon'
          className='w-16 h-16 object-contain'
        />
        <h3 className='text-white text-[20px] font-bold text-center'>
          {service.title}
        </h3>
      </div>
    </div>
  </div>
);

const About = () => {
  return (
    <div>

      <div className='bg-black h-full w-full text-white sm:flex sm:justify-around about py-12 mt-8 overflow-x-hidden' id='about'>
        <div className='flex flex-col justify-around'>
          <div className='sm:px-16 px-2'>
            <h2 className='text-4xl sm:text-5xl font-extrabold mt-2'>Introduction</h2>
            <p className='mt-3 mb-6 text-[17px] max-w-3xl leading-[30px]'>-- Hello, I'm Parth Patel, a passionate <a className='text-green-300 hover:text-green-500 duration-300' href='https://www.linkedin.com/in/parthpatelz' target='_blank'>Software developer </a>dedicated to crafting robust front-end and back-end systems, captivating websites, and mobile applications.
              <br />
              -- With extensive experience in MEAN and MERN stacks, Angular, Next.js, Spring Boot, Express, MySQL, and MongoDB, I bring a holistic approach to software development. Beyond coding, my enthusiasm for technology extends to exploring new software, gaming, and working with machines.<br/>
              -- Outside the tech world, I enjoy swimming and playing snooker. I am proud to have been a Winner, Gujarat State Open Ranking Billiards and Snooker Championship and third Runner-up, National Games of India – Swimming.
              <br/>
</p>
            <ButtonLink
              url='https://drive.google.com/file/d/19Vh0nx5JF9G_sgawb_dUIFlGSHtjp_dw/view?usp=drive_link'
              text='View Resume →'
              padding={`p-3`}
            />

          </div>
          <div className='mt-20 flex justify-center flex-wrap gap-7'>
            {services.map((service) => (
              <ServiceCard service={service} />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default About;